<template>
  <div class="mt-sp-111">
    <p class="text-2xl">Page Not Found</p>
  </div>
</template>

<script>

export default {
  components: { },
};
</script>

<style>
</style>
