import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./index.css";
import { useLoading } from  '@/stores'


const app = createApp(App);
const {loading, setLoading} = useLoading()

app.provide('loading', loading)
app.provide('setLoading', setLoading)
router.beforeEach((to, from, next) => {
    setLoading(true)
    setTimeout(() =>   next(), 750);
    setTimeout(() =>   setLoading(false), 2000);
})

app.use(router).mount("#app");
