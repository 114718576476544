<template>
  <div>
    <div class="background">
      <div class="h-screen">
        <search-box class="absolute mx-sp-16 rounded-10 right-0 left-0 md:mx-sp-140" />
      </div>
    </div>
    <div class="-mt-sp-40">
      <div class="-mt-sp-40 bg-primary-blue pt-sp-140 px-sp-16 md:px-0">
        <div class="flex justify-center bg-primary-light-blue pt-sp-80 rounded-t-lg">
          <iframe class="block md:hidden"
            src="https://www.facebook.com/plugins/video.php?height=174&href=https%3A%2F%2Fwww.facebook.com%2FRajpradisauto%2Fvideos%2F1761319600899614%2F&show_text=false&width=373&t=0"
            width="373" height="174" style="border: none; overflow: hidden" allowfullscreen="true"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
          <iframe class="hidden md:block"
            src="https://www.facebook.com/plugins/video.php?height=94&href=https%3A%2F%2Fwww.facebook.com%2FRajpradisauto%2Fvideos%2F1761319600899614%2F&show_text=false&width=160&t=0"
            width="760" height="426" style="border: none; overflow: hidden" allowfullscreen="true"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
        </div>
        <div class="py-sp-8 md:py-sp-40 flex justify-center md:space-x-sp-40 bg-primary-light-blue rounded-b-lg">
          <div class="w-1/4">
            <div class="flex justify-center">
              <iframe class="block md:hidden"
                src="https://www.facebook.com/plugins/video.php?height=32&href=https%3A%2F%2Fwww.facebook.com%2FRajpradisauto%2Fvideos%2F1761319600899614%2F&show_text=false&width=48&t=0"
                width="48" height="32" style="border: none; overflow: hidden" allowfullscreen="true"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
              <iframe class="hidden md:block"
                src="https://www.facebook.com/plugins/video.php?height=94&href=https%3A%2F%2Fwww.facebook.com%2FRajpradisauto%2Fvideos%2F1761319600899614%2F&show_text=false&width=160&t=0"
                width="160" height="94" style="border: none; overflow: hidden" allowfullscreen="true"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
            </div>
            <div>
              <p>ส่งมอบดีแม็ก 1.9 ปี18 อนุมัติไว ใน 1 ชั่วโมง!!</p>
            </div>
          </div>
          <div class="w-1/4">
            <div class="flex justify-center">
              <iframe class="block md:hidden"
                src="https://www.facebook.com/plugins/video.php?height=32&href=https%3A%2F%2Fwww.facebook.com%2FRajpradisauto%2Fvideos%2F1761319600899614%2F&show_text=false&width=48&t=0"
                width="48" height="32" style="border: none; overflow: hidden" allowfullscreen="true"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
              <iframe class="hidden md:block"
                src="https://www.facebook.com/plugins/video.php?height=94&href=https%3A%2F%2Fwww.facebook.com%2FRajpradisauto%2Fvideos%2F1761319600899614%2F&show_text=false&width=160&t=0"
                width="160" height="94" style="border: none; overflow: hidden" allowfullscreen="true"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
            </div>
            <div>
              <p>ส่งมอบดีแม็ก 1.9 ปี18 อนุมัติไว ใน 1 ชั่วโมง!!</p>
            </div>
          </div>
          <div class="w-1/4">
            <div class="flex justify-center">
              <iframe class="block md:hidden"
                src="https://www.facebook.com/plugins/video.php?height=32&href=https%3A%2F%2Fwww.facebook.com%2FRajpradisauto%2Fvideos%2F1761319600899614%2F&show_text=false&width=48&t=0"
                width="48" height="32" style="border: none; overflow: hidden" allowfullscreen="true"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
              <iframe class="hidden md:block"
                src="https://www.facebook.com/plugins/video.php?height=94&href=https%3A%2F%2Fwww.facebook.com%2FRajpradisauto%2Fvideos%2F1761319600899614%2F&show_text=false&width=160&t=0"
                width="160" height="94" style="border: none; overflow: hidden" allowfullscreen="true"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
            </div>
            <div>
              <p>ส่งมอบดีแม็ก 1.9 ปี18 อนุมัติไว ใน 1 ชั่วโมง!!</p>
            </div>
          </div>
          <div class="w-1/4">
            <div class="flex justify-center">
              <iframe class="block md:hidden"
                src="https://www.facebook.com/plugins/video.php?height=32&href=https%3A%2F%2Fwww.facebook.com%2FRajpradisauto%2Fvideos%2F1761319600899614%2F&show_text=false&width=48&t=0"
                width="48" height="32" style="border: none; overflow: hidden" allowfullscreen="true"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
              <iframe class="hidden md:block"
                src="https://www.facebook.com/plugins/video.php?height=94&href=https%3A%2F%2Fwww.facebook.com%2FRajpradisauto%2Fvideos%2F1761319600899614%2F&show_text=false&width=160&t=0"
                width="160" height="94" style="border: none; overflow: hidden" allowfullscreen="true"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
            </div>
            <div>
              <p>ส่งมอบดีแม็ก 1.9 ปี18 อนุมัติไว ใน 1 ชั่วโมง!!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-primary-blue p-sp-40 text-white space-y-sp-40">
      <div class="flex flex-row justify-center space-x-sp-4">
        <img src="@/assets/thumbs-up.svg" />
        <p class="text-white text-40">รถยอดนิยม</p>
      </div>
      <div class="grid grid-cols-2 md:grid-cols-4 gap-sp-8 md:gap-sp-40">
        <ItemList v-for="product in saleProductList" :key="product.id" :item-id="product.id"
          :item-brand-id="product.brandId" :item-brand-name="product.brandName" :item-description="product.description"
          :item-full-price="product.fullPrice" :item-price-sale="product.salePrice" :brand-icon="product.brandIcon"
          :item-thumbnail="product.thumbnail">
        </ItemList>
      </div>
    </div>
  </div>
</template>

<script>
import SearchBox from "@/components/SearchBox.vue";
import ItemList from "@/components/ItemList";
import { getProductSaleAll } from "@/api/product/index";
import { getBrandAll } from "@/api/brand/index";
import { useLoading } from "@/stores";

const { setLoading } = useLoading();

export default {
  components: { SearchBox, ItemList },
  data() {
    return {
      saleProductList: [],
      brands: {},
    };
  },
  async mounted() {
    setLoading(true);
    await this.setBrandList();
    await this.setSaleList();
    setTimeout(() => setLoading(false), 2000);
  },
  methods: {
    async setBrandList() {
      const brandResponse = await getBrandAll();
      let items = brandResponse.data;
      for (const index in items) {
        this.brands[items[index].id] = items[index];
      }
    },
    async setSaleList() {
      const saleResponse = await getProductSaleAll();
      const list = saleResponse.data;

      this.saleProductList = list.map((product) => {
        const a = {
          id: product.id,
          brandId: product.brand_id,
          brandName: product.brand_name,
          description: product.product_name,
          salePrice: product.price_sale,
          fullPrice: product.price,
          thumbnail: product.file_path,
          brandIcon: this.brands[product.brand_id].file_path,
        };
        return a;
      });
    },
  },
};
</script>

<style lang="css" scoped>
@media screen and (max-width: 639px) {
  div.background {
    background-image: url("@/assets/image/mobile/banner.png") !important;
    height: 375px;
  }
}

div.background {
  background-image: url("@/assets/image/banner.png");
  width: 100%;
  height: 793px;
  left: 0%;
  right: 0%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
</style>
