<template>
  <nav class="z-50 w-screen bg-white text-white sticky top-0">
    <div class="bg-primary-blue px-4 lg:px-8">
      <div class="flex justify-between h-20 lg:h-28 items-center">
        <div
          class="flex flex-row w-full md:p-sp-16 items-center text-18 md:space-x-sp-33"
        >
          <div class="flex w-1/3 justify-start lg:justify-center md:w-1/5">
            <router-link to="/" class="items-center">
              <div class="flex justify-center items-center">
                <img
                  src="@/assets/header/logo.svg"
                  class="w-full h-auto md:w-167 md:h-79"
                />
              </div>
            </router-link>
          </div>
          <div
            class="hidden lg:flex flex-row items-center md:space-x-sp-32 px-sp-40 md:w-full"
          >
            <router-link
              v-for="menu in menus"
              class="text-white"
              :to="menu.link"
              :key="menu.link.replace('/', '')"
            >
              <div class="flex flex-row space-x-sp-16">
                <div>
                  <img :src="menu.icon" />
                </div>
                <div>
                  <span>{{ menu.label }}</span>
                </div>
              </div>
            </router-link>
          </div>
          <div
            class="flex bg-primary-orange justify-center items-center text-white px-sp-16 py-sp-8 rounded-6 mx-sp-12 w-2/3 md:w-3/5 lg:w-1/5"
          >
            <span class="blink"> สั่งซื้อเลย! </span>
          </div>
        </div>

        <div class="lg:hidden flex flex-row items-center space-x-sp-12">
          <div>
            <p class="text-white">เมนู</p>
          </div>
          <button
            class="outline-none mobile-menu-button w-8"
            @click="toggleMobileHeader"
          >
            <img v-if="!isMobileMenuOpen" src="@/assets/hamburger.svg" />
            <svg
              v-else
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M27.125 25.5781C27.5469 26.0469 27.5469 26.75 27.125 27.1719C26.6562 27.6406 25.9531 27.6406 25.5312 27.1719L20 21.5938L14.4219 27.1719C13.9531 27.6406 13.25 27.6406 12.8281 27.1719C12.3594 26.75 12.3594 26.0469 12.8281 25.5781L18.4062 20L12.8281 14.4219C12.3594 13.9531 12.3594 13.25 12.8281 12.8281C13.25 12.3594 13.9531 12.3594 14.375 12.8281L20 18.4531L25.5781 12.875C26 12.4062 26.7031 12.4062 27.125 12.875C27.5938 13.2969 27.5938 14 27.125 14.4688L21.5469 20L27.125 25.5781Z"
                fill="white"
              />
              <rect
                x="1"
                y="1"
                width="38"
                height="38"
                rx="3"
                stroke="#FBAB0F"
                stroke-width="2"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div
      class="mobile-menu lg:hidden w-screen"
      :class="
        isMobileMenuOpen
          ? 'visible opacity-1 h-screen'
          : 'invisible opacity-0 h-0'
      "
    >
      <div>
        <ul
          class="bg-white text-primary-blue text-left divide-y divide-primary-orange mx-sp-16"
        >
          <li v-for="menu in menus" :key="menu.link.replace('/', '')">
            <router-link
              :to="menu.link"
              class="block bg-np-light text-2xl px-2 py-4 font-light"
            >
              <div class="flex flex-row space-x-sp-16">
                <div>
                  <img :src="menu.darkIcon" />
                </div>
                <div>
                  <span>{{ menu.label }}</span>
                </div>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      startDate: null,
      endDate: null,
      isMobileMenuOpen: false,
      isMobileCheckRoom: false,
      isMobileAboutOpen: false,
      isMobileRoomsOpen: false,
      isMobileExperienceOpen: false,
      isMobileMediaRoomOpen: false,
      openCalendarStartDate: false,
      openCalendarEndDate: false,
      menus: [
        {
          link: "/brands",
          icon: require("@/assets/header/brand.svg"),
          darkIcon: require("@/assets/header/mobile/brand.svg"),
          label: "รถรุ่นทั้งหมด",
        },
        {
          link: "/about",
          icon: require("@/assets/header/about.svg"),
          darkIcon: require("@/assets/header/mobile/about.svg"),
          label: "เกี่ยวกับเรา",
        },
        {
          link: "/document",
          icon: require("@/assets/header/file.svg"),
          darkIcon: require("@/assets/header/mobile/file.svg"),
          label: "เอกสารประกอบการซื้อ",
        },
        {
          link: "/contact",
          icon: require("@/assets/header/phone.svg"),
          darkIcon: require("@/assets/header/mobile/phone.svg"),
          label: "ติดต่อเรา",
        },
      ],
    };
  },
  components: {},
  watch: {
    isMobileMenuOpen: {
      handler: async function (isMobileMenuOpen) {
        if (!isMobileMenuOpen) {
          this.isMobileAboutOpen = false;
          this.isMobileRoomsOpen = false;
          this.isMobileExperienceOpen = false;
          this.isMobileMediaRoomOpen = false;
        }
      },
    },
    "$route.path": {
      handler: async function (current_path) {
        this.isMobileMenuOpen = false;
        if (current_path === "/") this.isHome = true;
        else this.isHome = false;
      },
    },
  },
  methods: {
    toggleMobileHeader() {
      if (this.isMobileMenuOpen) {
        this.isMobileCheckRoom = false;
        this.openCalendarStartDate = false;
        this.openCalendarEndDate = false;
      }
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
  },
  mounted() {
    this.startDate = new Date();
    var date = new Date();
    date.setDate(date.getDate() + 1);
    this.endDate = date;
    window.addEventListener("scroll", this.updateScroll);
  },
};
</script>

<style scoped>
.dropdown:hover .dropdown-menu {
  visibility: visible;
  opacity: 1;
}

.dropdown-menu {
  visibility: hidden;
  opacity: 0;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
}

.dropdown-menu li a {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
}

.material-design-icon.icon-2x .material-design-icon__svg {
  height: 2em;
  width: 2em;
}

input,
select {
  background: transparent;
  border: none;
  position: relative;
  display: block;
  outline: none;

  height: 42px;
  padding: 10px;
  color: #333;
  border: 0;
  outline: 0;
  background: transparent;
}

nav {
  font-weight: 300;
}

.dropdown-menu li:not(:last-child) div {
  border-bottom-width: 1px;
}

.blink {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
</style>
