<template>
  <router-link :to="`/product?id=${itemId}`"
    class="flex flex-col bg-white text-primary-blue border-2 p-4 justify-center rounded-2xl p-6">
    <div class="flex flex-col text-18 space-y-sp-16">
      <div class="flex flex-row justify-center space-x-sp-12">
        <div class="w-sp-24 h-sp-24">
          <img class="rounded" :src="brandIcon" />
        </div>
        <div>
          <span>{{ itemBrandName }}</span>
        </div>
      </div>
    </div>
    <div class="flex items-center h-full py-sp-8">
      <p class="text-18 line-clamp-3 text-primary-orange" v-html="descriptionText"></p>
    </div>
    <div class="flex flex-col justify-center mt-auto text-xs">
      <div>
        <img width="312" height="200" :src="itemThumbnail" />
      </div>
      <div class="py-sp-8 text-20">
        <p class="space-x-sp-8">
          <span class="line-through">{{ itemFullPrice }}</span>
          <span class="text-red-600">{{ itemPriceSale }}</span>
        </p>
      </div>
      <div class="bg-primary-orange text-white rounded-lg p-2">
        <p>ดูรถคันนี้</p>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    brandIcon: {
      type: String,
    },
    itemId: {
      type: Number,
    },
    itemBrandId: {
      type: String,
    },
    itemBrandName: {
      type: String,
    },
    itemDescription: {
      type: String,
    },
    itemThumbnail: {
      type: String,
    },
    itemFullPrice: {
      type: String,
    },
    itemPriceSale: {
      type: String,
    },
  },
  computed: {
    descriptionText() {
      let temp = this.itemDescription.toUpperCase();
      // console.log(this.itemBrandName.toLowerCase());
      temp = temp.replace(
        this.itemBrandName.toUpperCase(),
        "<span class='text-primary-blue'>" +
        this.itemBrandName.toUpperCase() +
        "</span>"
      );
      return temp;
    },
  },
};
</script>
