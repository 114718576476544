import { createRouter, createWebHistory } from "vue-router";
import LandingPage from "../views/LandingPage.vue";
import PageNotFound from "../views/PageNotFound.vue";
import ProductSearch from "@/views/ProductSearch";

const routes = [
  {
    path: "/",
    name: "LandingPage",
    component: LandingPage,
  },
  {
    path: "/brands",
    name: "ProductSearch",
    component: ProductSearch,
  },
  {
    path: "/document",
    name: "DocumentPage",
    component: () => import("../views/DocumentPage.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/AboutUs.vue"),
  },
  {
    path: "/product",
    name: "Product",
    component: () => import("../views/ProductDetail.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/ContactUs.vue"),
  },
  { path:  '/:pathMatch(.*)*', component: PageNotFound }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
