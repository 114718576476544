<template>
  <div :class="'dropdown'">
    <div>
      <button class="py-2 px-2 rounded-md w-196 bg-white">
        <div class="flex flex-col">
          <div
            class="py-2 flex w-full block whitespace-no-wrap space-x-sp-16 text-16 content-end"
          >
            <div
              v-if="displayIcon"
              class="flex items-center justify-start w-1/5 h-sp-24"
            >
              <img :src="displayIcon" class="rounded" />
            </div>
            <div
              class="w-40 justify-centers truncate text-sm"
              :class="{
                'px-sp-12 text-gray-400': !displayIcon,
              }"
            >
              <span>{{ displayText }}</span>
            </div>
            <div class="flex items-center justify-end w-1/5 h-sp-24">
              <img src="@/assets/header/mobile/chevron-down.svg" />
            </div>
          </div>
        </div>
        <chevron-down-icon class="absolute" />
      </button>
      <div class="ml-sp-30 md:ml-sp-172 lg:ml-0">
        <ul
          class="flex flex-col dropdown-menu bg-white text-left absolute w-196 rounded-md"
        >
          <li
            v-for="item in items"
            class="flex px-2 w-full cursor-pointer"
            :key="item.id"
            @click="() => handleChange(item.id)"
          >
            <div
              class="py-2 flex w-full block whitespace-no-wrap space-x-sp-16 text-sm"
            >
              <div v-if="item.file_path">
                <img
                  width="24"
                  height="24"
                  :src="item.file_path"
                  class="rounded"
                />
              </div>
              <div
                :class="{
                  'px-sp-12': !item.file_path,
                }"
              >
                <span>{{ item.name }}</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
    },
    selectedValue: {
      type: String,
    },
  },
  data() {
    return {
      currentItem: 0,
    };
  },
  computed: {
    readonly() {
      return !this.items || this.items.length == 0;
    },
    displayText() {
      return this.getText();
    },
    displayIcon() {
      return this.getIcon();
    },
  },
  methods: {
    handleChange(value) {
      this.currentItem = value;
      this.$emit("onChange", value);
    },
    getText() {
      for (const index in this.items) {
        if (
          this.items[index].id == this.currentItem ||
          this.items[index].id == this.selectedValue
        ) {
          return this.items[index].name;
        }
      }
      return "ทั้งหมด";
    },
    getIcon() {
      for (const index in this.items) {
        if (
          (this.items[index].id == this.currentItem ||
            String(this.items[index].id) == this.selectedValue) &&
          this.items[index].file_path
        ) {
          return this.items[index].file_path;
        }
      }
      return null;
    },
  },
};
</script>

<style scoped>
.dropdown:hover .dropdown-menu {
  visibility: visible;
  opacity: 1;
}

.dropdown-menu {
  visibility: hidden;
  opacity: 0;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
  margin: 4px;
}

.dropdown-menu li a {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
}

.material-design-icon.icon-2x .material-design-icon__svg {
  height: 2em;
  width: 2em;
}

input,
select {
  background: transparent;
  border: none;
  position: relative;
  display: block;
  outline: none;

  height: 42px;
  padding: 10px;
  color: #333;
  border: 0;
  outline: 0;
  background: transparent;
}
nav {
  font-weight: 300;
}

.dropdown-menu li:not(:last-child) {
  border-bottom-width: 1px;
}
</style>
