<template>
  <div>
    <div class="background">
      <div class="h-screen">
        <search-box class="absolute mx-sp-16 rounded-10 right-0 left-0 md:mx-sp-140" @onSearch="handleSearch" />
      </div>
    </div>
    <div class="bg-primary-blue p-sp-40 text-white space-y-sp-40">
      <div class="flex flex-row justify-center space-x-sp-4 mt-sp-48">
        <img src="@/assets/thumbs-up.svg" />
        <p class="text-white text-40">รถยอดนิยม</p>
      </div>
      <div v-if="!saleProductList || saleProductList.length == 0">
        <p class="flex w-full justify-center">ไม่พบรายการที่ค้นหา</p>
      </div>
      <div v-else class="grid grid-cols-2 md:grid-cols-4 gap-sp-8 md:gap-sp-40">
        <ItemList v-for="product in saleProductList" :key="product.id" :item-id="product.id"
          :item-brand-id="product.brandId" :item-brand-name="product.brandName" :item-description="product.description"
          :item-full-price="product.fullPrice" :item-price-sale="product.salePrice" :item-thumbnail="product.thumbnail">
        </ItemList>
      </div>
    </div>
  </div>
</template>

<script>
import SearchBox from "@/components/SearchBox.vue";
import ItemList from "@/components/ItemList";
import { getProductByCriteria, getProductSaleAll } from "@/api/product/index";
import { useLoading } from "@/stores";

const { setLoading } = useLoading();

export default {
  components: { SearchBox, ItemList },
  data() {
    return {
      items: [],
    };
  },
  watch: {
    "$route.query": {
      handler: function (selectItem) {
        this.handleSearch(selectItem);
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    saleProductList() {
      return this.items;
    },
  },
  mounted() {
    setLoading(true);
    this.setSaleList(
      this.$route.query.brand_id ? this.$route.query.brand_id : "",
      this.$route.query.model_id ? this.$route.query.model_id : "",
      this.$route.query.year ? this.$route.query.year : "",
      this.$route.query.price ? this.$route.query.price : "",
      1,
      10
    );
    setTimeout(() => setLoading(false), 2000);
  },
  methods: {
    handleSearch(selectItem) {
      setLoading(true);
      this.setSaleList(
        selectItem.brand_id,
        selectItem.model_id,
        selectItem.year,
        selectItem.price,
        1,
        10
      );
      setTimeout(() => setLoading(false), 2000);
    },
    async setSaleList(brand_id, model, year, price, page, limit) {
      let list;
      if (brand_id > 0 || model > 0 || year > 0 || price > 0) {
        const saleResponse = await getProductSaleAll(page, limit);
        list = await saleResponse.data.data;
      } else {
        const saleResponse = await getProductByCriteria(
          brand_id,
          model,
          year,
          price,
          page,
          limit
        );
        list = await saleResponse.data.data;
      }
      this.items = list.map((product) => {
        const a = {
          id: product.id,
          brandId: product.brand_id,
          brandName: product.brand_name_th,
          description: product.product_name_th,
          salePrice: product.price_sale,
          fullPrice: product.price,
          thumbnail: product.file_path,
        };
        console.log(a);
        return a;
      });
    },
  },
};
</script>

<style lang="css" scoped>
@media screen and (max-width: 639px) {
  div.background {
    background-image: url("@/assets/image/mobile/banner.png") !important;
    height: 375px;
  }
}

div.background {
  background-image: url("@/assets/image/banner.png");
  width: 100%;
  height: 793px;
  left: 0%;
  right: 0%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
</style>
