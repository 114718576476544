<template>
  <div class="relative text-primary-blue bg-primary-light-blue">
    <loading-screen />
    <desktop-header />
    <router-view />
    <DesktopFooter />
  </div>
</template>

<script>
import DesktopHeader from "@/components/DesktopHeader.vue";
import DesktopFooter from "@/components/DesktopFooter";
import LoadingScreen from "@/components/LoadingScreen.vue";

export default {
  name: "App",
  components: { DesktopHeader, DesktopFooter, LoadingScreen },
};
</script>

<style scoped></style>
