<template>
  <div class="background text-center md:text-left w-full md:w-500">
    <h1 class="text-40 md:text-56 text-primary-orange">
      <span>รถมือสอง</span><br /><span>ราชประดิษฐ์ออโต้</span>
    </h1>
    <p class="text-primary-blue text-24 md:text-32">
      <span class="whitespace-nowrap">เราบริการด้วยใจภายใต้คุณภาพ</span>
       <span class="whitespace-nowrap">และราคาที่เหมาะสมเพื่อลูกค้าทุกคน</span>
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="css" scoped>

@media screen and (max-width: 639px) {
}

</style>
