<template>
  <div
    class="curtain w-screen h-screen bg-primary-blue flex flex-col items-center"
    v-if="loading !== false"
  >
    <div class="pt-sp-24 flex md:hidden">
      <img src="@/assets/header/logo.svg" />
    </div>
    <div class="flex flex-col justify-center items-center h-full">
      <div class="flex justify-center items-center">
        <svg
          class="donut"
          width="81"
          height="80"
          viewBox="0 0 81 80"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.5 40C0.5 17.9688 18.3125 0 40.5 0C62.5312 0 80.5 17.9688 80.5 40C80.5 62.1875 62.5312 80 40.5 80C18.3125 80 0.5 62.1875 0.5 40ZM36.4375 57.5L24.875 42.5H8C9.25 58.125 21.2812 70.625 36.75 72.3438V57.6562C36.5938 57.6562 36.5938 57.5 36.4375 57.5ZM44.25 57.6562V72.3438C59.5625 70.625 71.5938 58.125 72.8438 42.5H55.9688L44.4062 57.5C44.25 57.5 44.25 57.6562 44.25 57.6562ZM55.5 35H72.5312C70.1875 19.5312 56.5938 7.5 40.5 7.5C24.25 7.5 10.6562 19.5312 8.3125 35H25.5L26.5938 32.8125C27.375 31.0938 29.0938 30 30.9688 30H49.875C51.75 30 53.4688 31.0938 54.25 32.8125L55.5 35Z"
            fill="#FBAB0F"
          />
        </svg>
      </div>
      <div class="flex justify-center items-center">
        <p class="text-2xl text-white">กรุณารอสักครู่...</p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject } from "vue";

export default {
  components: {},
  setup() {
    const initialLoading = ref(true);
    setTimeout(() => (initialLoading.value = false), 2000);

    const loading = inject("loading");

    return {
      initialLoading,
      loading,
    };
  },
};
</script>

<style scoped>
.curtain {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
}

.donut {
  animation: 3s spin infinite linear;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
