<template>
  <div>
    <div class="absolute pt-sp-48 lg:px-0 px-sp-16 w-full justify-start lg:pt-sp-264">
      <banner-layout />
    </div>
    <div class="bg-primary-orange pt-sp-18 px-sp-24 pb-sp-24 lg:px-sp-40 lg:pb-sp-40 mt-sp-368 lg:mt-sp-616">
      <div>
        <span id="search-label" class="text-24 text-white font-semibold">ค้นหารถ</span>
      </div>
      <div class="flex flex-col space-y-sp-8 space-x-0 lg:space-x-sp-40 lg:flex-row lg:space-y-0 pt-sp-26">
        <div class="w-full lg:w-1/4">
          <div class="grid grid-cols-7 gap-x-sp-8 items-center">
            <div class="justify-between text-justify text-right lg:text-left">
              <span>ยี่ห้อ:</span>
            </div>
            <div class="col-span-6">
              <select-button class="w-full" :selected-value="selectedBrand" @onChange="handleChangeBrand"
                :items="brands" />
            </div>
          </div>
        </div>
        <div class="w-full lg:w-1/4">
          <div class="grid grid-cols-7 gap-x-sp-8 items-center">
            <div class="justify-between text-justify text-right lg:text-left">
              <span>รุ่น:</span>
            </div>
            <div class="col-span-6">
              <select-button class="w-full" :selected-value="selectedModel" @onChange="handleChangeModel"
                :items="models" />
            </div>
          </div>
        </div>
        <div class="w-full lg:w-1/4">
          <div class="grid grid-cols-7 gap-x-sp-8 items-center">
            <div class="justify-between text-justify text-right lg:text-left">
              <span>โฉม:</span>
            </div>
            <div class="col-span-6">
              <select-button class="w-full" :selected-value="selectedYear" @onChange="handleChangeYear" :items="years" />
            </div>
          </div>
        </div>
        <div class="w-full lg:w-1/4">
          <div class="grid grid-cols-7 gap-x-sp-8 items-center">
            <div class="justify-between text-justify text-right lg:text-left">
              <span>ราคา:</span>
            </div>
            <div class="col-span-6">
              <select-button class="w-full" :selected-value="selectedPrice" @onChange="handleChangePrice"
                :items="prices" />
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="flex flex-row justify-center w-full">
        <button
          @click="searchProduct"
          class="text-white bg-primary-red px-sp-16 py-sp-8 rounded-6 mt-sp-24 w-full lg:w-295"
        >
          ค้นหา
        </button>
      </div> -->
    </div>
  </div>
</template>

<script>
import { getBrandAll } from "@/api/brand";
import { getModelAll } from "@/api/product_model/index";
import SelectButton from "@/components/SelectButton.vue";
import BannerLayout from "@/components/BannerLayout";
import { useLoading } from "@/stores";

const { setLoading } = useLoading();

export default {
  name: "SearchBox",
  data() {
    return {
      selectedValue: {
        brand_id: "0",
        model_id: "0",
        year: "0",
        price: "0",
      },
      brands: [],
      models: [],
      years: [],
      prices: [],
    };
  },
  components: { SelectButton, BannerLayout },
  mounted() {
    setLoading(true);
    this.setBrandList();
    if (this.$route.query) {
      if (this.$route.query.brand_id > 0) {
        this.selectedValue.brand_id = this.$route.query.brand_id;
      }
      if (this.$route.query.brand_id) {
        this.setModelList(
          this.$route.query.brand_id ? this.$route.query.brand_id : ""
        );
        this.selectedValue.model_id = this.$route.query.model_id;
      }
      if (this.$route.query.year) {
        this.setYearList();
        this.selectedValue.year = this.$route.query.year;
      }
      if (this.$route.query.price) {
        this.setPriceList();
        this.selectedValue.price = this.$route.query.price;
      }
    }
    setTimeout(() => setLoading(false), 2000);
  },
  watch: {
    "selectedValue.brand_id": {
      handler: function (brand_id) {
        this.searchProductByValue({
          brand_id: brand_id,
          model_id: "0",
          year: "0",
          price: "0",
        });
      },
      deep: true,
      immediate: true,
    },
    "selectedValue.model_id": {
      handler: function (model_id) {
        this.searchProductByValue({
          brand_id: this.selectedValue.brand_id,
          model_id: model_id,
          year: "0",
          price: "0",
        });
      },
      deep: true,
      immediate: true,
    },
    "selectedValue.year": {
      handler: function (year) {
        this.searchProductByValue({
          brand_id: this.selectedValue.brand_id,
          model_id: this.selectedValue.model_id,
          year: year,
          price: "0",
        });
      },
      deep: true,
      immediate: true,
    },
    "selectedValue.price": {
      handler: function (price) {
        this.searchProductByValue({
          brand_id: this.selectedValue.brand_id,
          model_id: this.selectedValue.model_id,
          year: this.selectedValue.year,
          price: price,
        });
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    selectedBrand() {
      return this.selectedValue && this.selectedValue.brand_id;
    },
    selectedYear() {
      return this.selectedValue && this.selectedValue.year;
    },
    selectedModel() {
      return this.selectedValue && this.selectedValue.model_id;
    },
    selectedPrice() {
      return this.selectedValue && this.selectedValue.price;
    },
  },
  methods: {
    handleChangeBrand(value) {
      this.selectedValue.brand_id = value;
      this.setModelList(value);
    },
    handleChangeModel(value) {
      this.selectedValue.model_id = value;
      this.setYearList();
    },
    handleChangeYear(value) {
      this.selectedValue.year = value;
      this.setPriceList();
    },
    handleChangePrice(value) {
      this.selectedValue.price = value;
    },
    searchProduct() {
      this.handlePage(
        this.selectedValue.brand_id,
        this.selectedValue.model_id,
        this.selectedValue.year,
        this.selectedValue.price
      );
    },
    searchProductByValue(selectedValue) {
      this.handlePage(
        selectedValue.brand_id,
        selectedValue.model_id,
        selectedValue.year,
        selectedValue.price
      );
    },
    handlePage(brand_id, model_id, year, price) {
      this.$router.push(
        `/brands?brand_id=${brand_id}&model_id=${model_id}&year=${year}&price=${price}`
      );
    },
    priceFormat(number) {
      return new Intl.NumberFormat({ style: "currency" }).format(number);
    },
    async setBrandList() {
      const brandResponse = await getBrandAll();
      const brands = await brandResponse.data;
      console.log(brands)
      this.brands = brands.map((brand) => {
        return {
          id: brand.id,
          name: brand.brand_name_th ? brand.brand_name_en : "Undefine",
          file_path: brand.file_path ? brand.file_path : "https://placehold.co/600x400",
        };
      });
    },
    async setModelList(brand_id) {
      const modelResponse = await getModelAll(brand_id);
      const models = await modelResponse.data;
      this.models = models.map((model) => {
        return {
          id: model.id,
          name: model.model_name_en,
          brand_id: model.brand_id,
        };
      });
    },
    setYearList() {
      for (let i = 2022; i > 2000; i--) {
        this.years.push({
          id: i,
          name: i.toString(),
        });
      }
    },
    setPriceList() {
      this.prices = [
        {
          id: "asc",
          file_path: require("@/assets/header/mobile/sort_desc.svg"),
          name: "น้อยไปมาก",
        },
        {
          id: "desc",
          file_path: require("@/assets/header/mobile/sort_desc.svg"),
          name: "มากไปน้อย",
        },
      ];
    },
  },
};
</script>

<style>
#search-label::before {
  content: url("@/assets/search.svg");
  display: inline-flex;
  margin: 8px;
  align-items: center;
}
</style>
