<template>
  <div class="bg-primary-orange text-primary-blue py-sp-101 space-y-sp-16">
    <div class="flex flex-row justify-center space-x-sp-40">
      <div>
        <img src="@/assets/header/facebook.svg">
      </div>
      <div>
        <img src="@/assets/header/line.svg">
      </div>
    </div>
    <div class="font-extrabold text-32 pt-sp-24">
      <p>รถมือสองราชประดิษฐ์ออโต้</p>
    </div>
    <div class="text-24">
      <p>ที่อยู่ : 1/1 ถนนมีนบุรี แขวงมีนบุรี เขตมีนบุรี กรุงเทพ 10510</p>
    </div>
    <div class="text-24">
      <p>1/1 Nimit Mai Rd, Khwaeng Min Buri, Min Buri, Bangkok 10510</p>
    </div>
    <div class="flex justify-center pt-sp-16">
      <button class="flex flex-row bg-primary-blue text-white py-sp-12 px-sp-78 rounded-md space-x-sp-10">
        <div>
          <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.5 16.0469L17.9062 14.0781C16.875 13.6094 15.7031 13.9375 15 14.7812L13.7344 16.3281C11.625 15.1094 9.89062 13.375 8.67188 11.3125L10.2656 10.0469C11.0625 9.34375 11.3906 8.17188 10.9688 7.1875L8.95312 2.5C8.48438 1.42188 7.3125 0.8125 6.14062 1.09375L1.875 2.07812C0.75 2.3125 0 3.29688 0 4.46875C0 15.8125 9.1875 25 20.5312 25C21.7031 25 22.6875 24.25 22.875 23.125L23.8594 18.8594C24.1875 17.6875 23.5781 16.5156 22.5 16.0469ZM21.7031 18.3438L20.7188 22.6094C20.7188 22.6562 20.625 22.75 20.5312 22.75C10.4531 22.75 2.20312 14.5469 2.20312 4.46875C2.20312 4.375 2.29688 4.28125 2.39062 4.28125L6.65625 3.29688L6.70312 3.25C6.79688 3.25 6.84375 3.34375 6.89062 3.39062L8.85938 7.98438C8.90625 8.07812 8.90625 8.17188 8.8125 8.21875L6.51562 10.0938C6.14062 10.4219 6 10.9844 6.23438 11.4531C7.78125 14.5938 10.3594 17.1719 13.5 18.7188C13.9688 18.9531 14.5781 18.8594 14.9062 18.4375L16.7812 16.1406C16.8281 16.0938 16.9219 16.0469 17.0156 16.0938L21.6094 18.0625C21.7031 18.1562 21.75 18.25 21.7031 18.3438Z" fill="white"/>
          </svg>
        </div>
        <div>
          <span>090-8070622</span>
        </div>
      </button>
    </div>
  </div>
</template>
