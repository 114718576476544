import { getHttp } from "../http-common";

export function getModelAll(brandId) {
  if(brandId) {
  return getHttp().get(
    `/product_models/list?brand=${brandId}`
  );
  }
  return getHttp().get(
    `/product_models/list`
  );
}


export function getModelById(modelId) {
  return getHttp().get(
    `/product_models/${modelId}`
  );
}
